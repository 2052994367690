<template>
  <div>
    <div class="demo-container">
      <div v-if="dataLoading" class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div v-else-if="error.length !== 0" class="m-auto">
        {{ error }}
      </div>
      <div id="app" v-else-if="error.length === 0">
        <div v-if="can_list">
          <b-row class="xx" lg="12">
            <div class="col-xl-4 col-md-5 col-12">
              <div class="card card-congratulation-medal">
                <div class="cardd card-body">
                  <h5>{{ $t('statistics.report') }}</h5>
                  <p class="card-text font-small-3"></p>
                  <h3 class="mb-75 mt-2 pt-50">
                    <a href="javascript:void(0);"
                      >{{ openReportsCount }} {{ $t('statistics.reports') }}</a
                    >
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-xl-8 col-md-7 col-12">
              <div class="card card-statistics">
                <div class="row">
                  <div class="card-header">
                    <h4 class="card-title">Statistics</h4>
                    <div class="d-flex align-items-center">
                      <p class="card-text font-small-2 mr-25 mb-0"></p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="card-body statistics-body">
                    <div class="row">
                      <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                        <div class="media">
                          <div class="avatar bg-light-primary mr-2">
                            <div class="avatar-content">
                              <feather-icon icon="UserIcon" />
                            </div>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="font-weight-bolder mb-0">
                              {{ personelCount }}
                            </h4>
                            <p class="card-text font-small-3 mb-0">
                              {{ $t('statistics.personnel') }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0">
                        <div class="media">
                          <div class="avatar bg-light-info mr-2">
                            <div class="avatar-content">
                              <feather-icon icon="TruckIcon" />
                            </div>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="font-weight-bolder mb-0">
                              {{ machineCount }}
                            </h4>
                            <p class="card-text font-small-3 mb-0">
                              {{ $t('statistics.machine') }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-xl-3 mb-2 mb-sm-0">
                        <div class="media">
                          <div class="avatar bg-light-danger mr-2">
                            <div class="avatar-content">
                              <feather-icon icon="HelpCircleIcon" />
                            </div>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="font-weight-bolder mb-0">
                              {{ problemCount }}
                            </h4>
                            <p class="card-text font-small-3 mb-0">
                              {{ $t('statistics.activeProblem') }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-xl-3">
                        <div class="media">
                          <div class="avatar bg-light-success mr-2">
                            <div class="avatar-content">
                              <feather-icon icon="DollarSignIcon" />
                            </div>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="font-weight-bolder mb-0">
                              {{ amount.toLocaleString() }}
                              {{ currency.symbol || '' }}
                            </h4>
                            <p class="card-text font-small-3 mb-0">
                              {{ $t('statistics.rvn1') }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-row>
          <b-row>
            <div class="col-xl-6 col-md-7 col-12">
              <ecommerce-order-chart :data="workPersonnels" />
            </div>
            <div class="col-xl-6 col-md-7 col-12">
              <ecommerce-profit-chart :data="workMachines" />
            </div>
          </b-row>

          <b-row>
            <!-- Bar Chart - Orders -->

            <div class="col-xl-8 col-md-7 col-12">
              <ecommerce-revenue-report
                :data="revenue"
                :selectedYear="selectedYear.toString()"
                @change-year="changeYear"
              />
            </div>
            <div class="col-xl-4 col-md-5 col-12">
              <ecommerce-goal-overview :data="goalOverview" />
            </div>

            <!--                <ecommerce-order-chart :data="workMachines"/>-->

            <!--/ Bar Chart - Orders -->
            <!--              <b-col-->
            <!--                  lg="6"-->
            <!--                  md="3"-->
            <!--                  cols="6"-->
            <!--              >-->
            <!--                <ecommerce-profit-chart :data="data.statisticsProfit"/>-->
            <!--              </b-col>-->
            <!--              <b-col-->
            <!--                  lg="12"-->
            <!--                  md="6"-->
            <!--              >-->
            <!--                <ecommerce-earnings-chart :data="data.earningsChart"/>-->
            <!--              </b-col>-->
          </b-row>
        </div>
        <h1 v-else>{{ $t('noPermission') }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue';
import axios from '@/libs/axios';
import EcommerceOrderChart from './homeWidgets/EcommerceOrderChart';
import EcommerceGoalOverview from './homeWidgets/EcommerceGoalOverview';
import EcommerceProfitChart from './homeWidgets/EcommerceProfitChart.vue';
import EcommerceRevenueReport from './homeWidgets/EcommerceRevenueReport.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    BRow,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceGoalOverview,
    EcommerceRevenueReport
  },
  async mounted() {
    await this.getPageAuths();
    await this.getData();
    //await this.getDataPersonelCount();
    //await this.getDataMachineCount();
    await this.getDataProblemCount();
    await this.getDataAmount();
    await this.getDataAmountForYear(this.selectedYear);
    await this.getWorkPersonnelsFn();
    await this.getWorkMachinesFn();
  },
  data() {
    return {
      selectedYear: new Date().getFullYear().toString(),
      revenue: {
        years: ['2026', '2025', '2024', '2023', '2022', '2021'],
        price: '25,852',
        budget: '56,800',
        revenueReport: {
          series: [
            {
              name: this.$t('statistics.earning'),
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
          ]
        },
        budgetChart: {
          series: [
            {
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
          ]
        }
      },
      openReports: [],
      workPersonnels: null,
      goalOverview: {
        completed: '0',
        inProgress: '0',
        series: [0],
        categories: null
      },
      workMachines: null,
      openReportsCount: 0,
      personelCount: 0,
      amount: 0,
      currency: { symbol: '' },
      boqsSum: 0,
      allProgress: 0,
      machineCount: 0,
      problemCount: 0,
      dataLoading: false,
      yesterday: new Date(),
      error: '',
      can_list: true
    };
  },
  methods: {
    async changeYear(a) {
      this.selectedYear = a.toString();
      await this.getDataAmountForYear(a);
    },
    async getWorkPersonnelsFn() {
      this.dataLoading = true;
      const response = await axios.get('/workPersonnelForDashBoard');
      try {
        let data = response?.data || null;
        this.workPersonnels = data;
        this.personelCount = data.lastSum;

        this.dataLoading = false;
      } catch (e) {
        this.error = e?.response?.data?.message || e?.message;
        this.dataLoading = false;
      }
    },
    async getWorkMachinesFn() {
      this.dataLoading = true;
      const response = await axios.get('/workMachineForDashBoard');
      try {
        let data = response?.data || null;
        console.log(response?.data);

        this.workMachines = data;
        this.machineCount = data.lastSum;


        this.dataLoading = false;
      } catch (e) {
        this.error = e?.response?.data?.message || e?.message;
        this.dataLoading = false;
      }
    },
    async getDataAmount() {
      this.dataLoading = true;
      const response = await axios.get('/dashboard-amount');

      try {
        let data = response?.data || 0;
        this.amount = data.sum;
        this.currency = data.currency;
        this.goalOverview.inProgress = (
          data.boqsSum - data.allProgress
        ).toLocaleString();
        this.goalOverview.completed = data.allProgress.toLocaleString();
        this.goalOverview.series = [
          ((data.allProgress / data.boqsSum) * 100).toFixed(2)
        ];

        this.dataLoading = false;
      } catch (e) {
        this.error = e?.response?.data?.message || e?.message;
        this.dataLoading = false;
      }
    },
    async getDataAmountForYear(year) {
      this.dataLoading = true;
      const response = await axios.get('/dashboard-amount-year?year=' + year);

      try {
        let data = response?.data || 0;
        this.revenue.revenueReport.series[0].data = data.map((e) => e.value);
        this.revenue.budgetChart.series[0].data = data.map((e) => e.value);
        let sum = 0;
        data.forEach((a) => {
          sum += a.value;
        });
        this.revenue.price = sum.toLocaleString() + ' ' + this.currency.symbol;
        this.revenue.categories = [];
        for (let i = 0; i < 12; i++) {
          let d = new Date();
          d.setMonth(i);
          d.setFullYear(this.selectedYear);
          let m = d.toLocaleString(localStorage.dbLanguage, { month: 'short' });
          this.revenue.categories.push(m);
        }
        this.dataLoading = false;
      } catch (e) {
        this.error = e?.response?.data?.message || e?.message;
        this.dataLoading = false;
      }
    },
    /* async getDataPersonelCount() {
      this.dataLoading = true;
      const response = await axios.get('/dashboard-personnel');
      try {
        let data = response?.data || 0;
        let sum = 0;
        data.forEach((d) => {
          sum += Math.round(+(d.count / d.a));
        });
        this.personelCount = sum;
        this.dataLoading = false;
      } catch (e) {
        this.error = e?.response?.data?.message || e?.message;
        this.dataLoading = false;
      }
    }, */
    /* async getDataMachineCount() {
      this.dataLoading = true;
      const response = await axios.get('/dashboard-machine');
      try {
        let data = response?.data?.result || 0;
        let sum = 0;
        data.forEach((d) => {
          sum += d.count;
        });
        this.machineCount = sum;
        this.dataLoading = false;
      } catch (e) {
        this.error = e?.response?.data?.message || e?.message;
        this.dataLoading = false;
      }
    }, */
    async getDataProblemCount() {
      this.dataLoading = true;
      const response = await axios.get('/list-work-by-pivot-v2');
      try {
        let areas = response?.data?.allProblems || [];
        areas.forEach((o) => {
          o.problemId = uuidv4;
          if (o.problemIsResolved !== undefined) {
            if (o.problemIsResolved !== true) {
              o.problemIsResolved = false;
            }
          }
          o.problemIsResolvedName = o.problemIsResolved ? 'Active' : 'Passive';
        });
        let problemActiveData = [];
        areas.forEach((a) => {
          if (a.problemType) {
            if (a.problemIsResolved !== true) {
              const check = problemActiveData.find(
                (p) => p.problemType === a.problemType
              );
              if (check === undefined) {
                problemActiveData.push({
                  problemType: a.problemType,
                  count: 1
                });
              } else {
                check.count += 1;
              }
            }
          }
        });
        problemActiveData.forEach((p) => {
          this.problemCount += p.count;
        });
        // let sum = 0
        // data.forEach(d => {
        //   sum += d.count
        // })
        this.dataLoading = false;
      } catch (e) {
        this.error = e?.response?.data?.message || e?.message;
        this.dataLoading = false;
      }
    },
    async getData() {
      this.yesterday.setDate(this.yesterday.getDate() - 1);
      this.error = '';
      this.dataLoading = true;
      const response = await axios.get(
        '/list-user-for-work-approval?startDate=' +
          this.yesterday +
          '&endDate=' +
          this.yesterday
      );

      try {
        this.openReports = response?.data?.result || [];
        this.openReportsCount = this.openReports.filter(
          (a) => a.closed === false
        ).length;
        this.dataLoading = false;
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
        this.dataLoading = false;
      }
    },

    async getPageAuths() {
      //TODO link düzelecek
      const pageAuths = await axios.post('/get-page-auth', {
        page: 'Statistics'
      });
      // this.can_edit = pageAuths.data.can_edit;
      this.can_list = pageAuths.data.can_list;
      // this.can_delete = pageAuths.data.can_delete;
      // this.can_create = pageAuths.data.can_create;
    }
  }
};
</script>

<style lang="scss">
.fama-table img:not(.famayellow) {
  height: 100px;
  display: block;
}

@import './tables/tables.scss';

.card-statistics {
  flex-wrap: wrap;
  height: 90%;
}

.card-congratulation-medal {
  flex-wrap: wrap;
  height: 90%;
  display: flex;
}

.demo {
  flex-wrap: wrap;
}

.b-row {
  flex-wrap: wrap;
}
</style>
